import '../scss/site.scss';
import '../scss/framework-utils.scss';

import onPageReady from './utils/onPageReady';
import polyfill from './polyfills/polyfill-loader';

// import shareWidget from './modules/share.widget.js';
import { init as fedDocSearchInit } from './modules/fed-doc-search.js';

const sentryDsn = 'https://16862c8579834c9d98c33c6aecd9d448@o37692.ingest.sentry.io/4504787825393664';

function initialise() {
    fedDocSearchInit();

    if (sentryDsn) {
        import(/* webpackChunkName: "sentry" */ './modules/sentry.js')
            .then(({ default: sentry }) => {
                sentry.init(sentryDsn);
            });
    }

    /*  Mobile Menu  ------------------------- */
    const mobileNavEl = document.querySelector('.mobile-nav');
    if (mobileNavEl) {
        import(/* webpackChunkName: "mobile-nav" */ './modules/mobile.nav.js')
            .then(({ default: MobileNav }) => {
                new MobileNav(mobileNavEl);
            });
    }

    const fileUpload = document.querySelector('#id_avatar_image');
    if (fileUpload) {
        import(/* webpackChunkName: "max-file-upload" */ './modules/max-file-upload.js')
            .then(({ default: MaxFileUpload }) => {
                new MaxFileUpload();
            });
    }


    /*  Mobile Menu  ------------------------- */
    const onBoardingStages = document.querySelector('#onboarding-profile');
    if (onBoardingStages) {
        import(/* webpackChunkName: "onboarding" */ './modules/onboarding.js')
            .then(({ default: Onboarding }) => {
                new Onboarding();
            });
    }

    /*  Confirm Password  ------------------------- */
    const passwordEl = document.getElementById('ClientSiteForm');
    if (passwordEl) {
        import(/* webpackChunkName: "password-check" */ './modules/password-check.js')
            .then(({ default: PasswordCheck }) => {
                new PasswordCheck();
            });
    }

    const AppContainer = document.getElementById('TurnUpApp');
    if (AppContainer) {
        import(/* webpackChunkName: "tables" */ './components/app.js')
            .then(({ default: AppInit }) => {
                AppInit(AppContainer);
            });
    }

    /*  Tables  ------------------------ */
    const tables = document.querySelectorAll('table');
    if (tables.length > 0) {
        import(/* webpackChunkName: "tables" */ './modules/responsive-tables.js')
            .then(({ default: tablesInit }) => {
                tablesInit([...tables]);
            });
    }

    /*  Collapsibles  ------------------------ */
    const collapsibleEls = document.querySelectorAll('[data-collapse-control], .collapse-control');
    if (collapsibleEls.length > 0) {
        import(/* webpackChunkName: "collapsible" */ './modules/collapsible.js')
            .then(({ default: collapsibleInit }) => {
                collapsibleInit([...collapsibleEls]);
            });
    }

    /*  Parallax  ------------- */
    const parallaxEls = document.querySelectorAll('.js-parallax');
    if (parallaxEls.length > 0) {
        import(/* webpackChunkName: "parallax" */ './modules/parallax.js')
            .then(({ default: parallaxInit }) => {
                parallaxInit([...parallaxEls]);
            });
    }

    /*  Modal  ------------- */
    const ModalEls = document.querySelectorAll('.modal-toggle');
    if (ModalEls.length > 0) {
        import(/* webpackChunkName: "parallax" */ './modules/modal.js')
            .then(({ default: ModalInit }) => {
                ModalInit([...ModalEls]);
            });
    }
}

polyfill().then(() => onPageReady(initialise));
