import debounce from 'lodash/debounce';

class FedDocSearch {
    constructor(searchField, searchItems) {
        this.searchField = searchField;
        this.searchItems = this.setupSearchCache(searchItems);
        this.searchTerm = '';
        this.initListeners();

        this.iterateItems = debounce(this.iterateItems, 200);
    }

    setupSearchCache = (searchItems) => {
        return searchItems.map(item => ({
            el: item.parentNode,
            text: `${ item.innerHTML }${ item.getAttribute('data-search-terms') || '' }`.toLowerCase(),
        }));
    };

    toggleVis = (item) => {
        if (item.text.includes(this.searchTerm)) {
            item.el.classList.remove('hidden');
        } else {
            item.el.classList.add('hidden');
        }
    };

    iterateItems = () => {
        this.searchTerm = this.searchField.value.toLowerCase();
        this.searchItems.forEach(this.toggleVis);
    };

    focusSearch = (event) => {
        if (+event.keyCode === 191) {
            event.preventDefault();
            this.searchField.focus();
        }
    }

    initListeners() {
        this.searchField.addEventListener('change', this.iterateItems);
        this.searchField.addEventListener('keyup', this.iterateItems);
        window.addEventListener('keydown', this.focusSearch);
    }
}

export function init() {
    const searchField = document.getElementById('side-bar-search');
    const searchItems = [...document.querySelectorAll('.searchable-menu a')];

    if (searchField && searchItems.length > 0) {
        new FedDocSearch(searchField, searchItems);
    }
}
